<template>
  <div>
    <el-container>
      <!-- 顶部两个按钮 -->
      <el-header class="header">
        <el-button class="btn">新建线路</el-button>
        <el-button class="btn">清除线路</el-button>
      </el-header>
      <el-container>
        <!-- 主要部分左边 -->
        <el-main class="main">
          <el-container>
            <!-- 中间拖拽部分头部 -->
            <el-header style="background-color: #fff" class="main-top">
              <!-- 选项上部分 -->
              <el-row class="row-bg main-top-header" justify="space-between">
                <el-col :span="11" class="main-top-header-left"
                  >Untitled Experiment</el-col
                >
                <el-col :span="11" class="main-top-header-right">
                  <span>计算平台:</span>
                  <el-select
                    v-model="value"
                    class="m-2 select"
                    placeholder="中性原子量子计算模拟器"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.label"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <el-button class="btn">设置</el-button>
                  <el-button class="btn">保存</el-button>
                  <el-button type="primary" class="btn">运行试验</el-button>
                </el-col>
                
              </el-row>
              <!-- 可拖动的标签 -->
              <el-row class="row-bg main-tag" justify="space-between">
                <el-col :span="24" class="tag-container" id="tag-container">
                  <el-tag
                    ref="tag"
                    v-for="(item, index) in tagName1"
                    class="ml-2 tag-item"
                    size="large"
                    type="info"
                    >{{ item }}
                    <!-- <div class="contextMenu-wrap" @click="deleteMenu">
                      <div class="menu-item">删除</div>
                    </div> -->
                  </el-tag>
                  <!-- <div
                    ref="tag"
                    class="tag-item mytag"
                    v-for="(item, index) in tagName2"
                  >
                    {{ item }}
                  </div> -->
                  <div
                    ref="tag"
                    v-for="(item, index) in tagName2"
                    class="tag-item mytag"
                  >
                    {{ item }}
                    <div
                      class="circle"
                      @click="circleClick"
                      :style="{ top: '-45px' }"
                    ></div>
                    <div
                      class="circle"
                      @click="circleClick"
                      :style="{ top: '55px' }"
                    ></div>
                    <div
                      class="circle"
                      @click="circleClick"
                      :style="{ top: '105px' }"
                    ></div>
                    <div class="connect-line" style="display: none"></div>
                  </div>
                </el-col>
              </el-row>
              <!-- 可拖入的表 -->
              <el-scrollbar style="height: 250px">
                <div class="table-container">
                  <div class="mian-table">
                    <div
                      class="mian-table-item"
                      v-for="(item, index) in tableItem"
                    >
                      <span class="number">{{ item }}</span>
                      <div
                        class="table-line"
                        :data-drop="item"
                        id="tag-container"
                        ref="toContainer"
                      >
                        <!-- <div class="line"></div> -->
                        <!-- 当更改数据时追加的标签 -->
                        <!-- <el-tag
                          v-if="showAfterEditTag"
                          :class="[( item2==('H' || 'X'|| 'Y'|| 'Z'|| 'RX'|| 'RY')) ? 'ml-2 tag-item':'tag-item mytag']"
                          size="large"
                          type="info"
                          ref="addTag"
                          v-for="item2 in initDropData[item]"
                          >{{ item2 }}</el-tag
                        >
                          <span
                          v-if="showAfterEditTag"
                          :class="[( item2==('H' || 'X'|| 'Y'|| 'Z'|| 'RX'|| 'RY')) ? 'ml-2 tag-item':'tag-item mytag']"
                          size="large"
                          type="info"
                          ref="addTag"
                          v-for="item2 in initDropData[item]"
                          >{{ item2 }}</span
                        > -->

                        <el-tag
                          v-if="showAfterEditTag"
                          class="ml-2 tag-item"
                          size="large"
                          type="info"
                          ref="addTag"
                          v-show="tagName1.indexOf(item2) != -1"
                          v-for="item2 in initDropData[item]"
                          >{{ item2 }}</el-tag
                        >
                        <span
                          v-if="showAfterEditTag"
                          class="tag-item mytag"
                          size="large"
                          type="info"
                          ref="addTag"
                          v-show="tagName2.indexOf(item2) != -1"
                          v-for="item2 in initDropData[item]"
                          >{{ item2 }}</span
                        >
                        <!-- :class="[(item2==('H' || 'X'|| 'Y'|| 'Z'|| 'RX'|| 'RY')) ? 'ml-2 tag-item':'tag-item mytag']" -->
                        <!-- v-show="item2 == 'H' || 'X'|| 'Y'|| 'Z'|| 'RX'|| 'RY'|| 'CONT'|| 'CR'|| 'CZ'"    -->
                        <!-- v-show="item2 == 'CONT'||'CR'||'CZ'" -->
                        <!-- <el-tag
                          v-if="showAfterEditTag"   
                          class="tag-item mytag"     
                          size="large"
                          type="info"
                          ref="addTag"
                          v-for="item2 in initDropData[item]"
                          >{{ item2 }}</el-tag
                        > -->
                      </div>
                      <div class="line"></div>
                    </div>
                  </div>
                  <div class="table-add">
                    <el-icon class="sub-btn" @click="addTable"
                      ><ZoomOut
                    /></el-icon>
                    <el-icon class="add-btn" @click="addTable"
                      ><CirclePlus
                    /></el-icon>
                    <div class="number">
                      <div class="number-item" v-for="item in 100">
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </el-header>
            <!-- 下面两个表部分 -->
            <el-main>
              <div class="echart-container">
                <div class="echart-item">
                  
                </div>
                <div class="echart-item"></div>
              </div>
              <!-- <el-container>
                <el-aside style="background-color: #fff">mianleft</el-aside>
                <el-main style="background-color: #fff">mainright</el-main>
              </el-container> -->
            </el-main>
          </el-container>
        </el-main>
        <!-- 右边侧边栏 -->
        <el-aside class="aside" style="background-color: #fff" width="400px">
          <!-- 第一行 头部-->
          <el-row class="row-bg aside-header" justify="space-between">
            <el-col :span="12" class="aside-header-left">
              <span>代码编译器:</span>
              <el-icon class="warning-icon"><Warning /></el-icon>
            </el-col>
            <el-col :span="12" class="aside-header-right">
              <el-button
                v-show="errmsg"
                type="danger"
                size="small"
                plain
                class="errmsg-btn"
              >
                <el-icon style="vertical-align: middle">
                  <CircleClose />
                </el-icon>
                <span style="vertical-align: middle">错误语法</span>
              </el-button>
              <el-button class="more-btn" type="info" plain size="small">
                <el-icon style="vertical-align: middle">
                  <MoreFilled />
                </el-icon>
              </el-button>
            </el-col>
          </el-row>
          <!-- 下面的列表 -->
          <div class="aside-list">
            <!-- <div class="contaienr" v-for="(item, i) in resData">
              <div class="list-item" v-for="(item2, j) in item">
                <span>{{ resData[j][i]}}</span>
                <el-divider />
              </div>
            </div> -->
            <!-- <div class="list-item" v-for="(item, i) in resData">
              <span>{{ item }}</span>
              <el-divider />
            </div> -->
            <prism-editor
              @input="editorChange"
              @keydown="editorKeyDown"
              class="my-editor"
              style="background-color: white"
              v-model="code"
              :highlight="highlighter"
              :line-numbers="lineNumbers"
            ></prism-editor>
          </div>
        </el-aside>
        <!-- <button @click="test">annou</button> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import http from '@/utils/API/request.js'
import Sortable from "sortablejs";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles
export default {
  components: {
    PrismEditor,
  },
  data() {
    return {
      showDeleteMenu: false,
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
      ],
      currentTag: "",
      isRactTag: true,
      tagClassName: "tag-item mytag",
      showAfterEditTag: false,
      initDropData: [],
      errmsg: false,
      value: "",
      options: "",
      decrIcon: false,
      tem: null,
      tagName: ["H", "X", "Y", "Z", "RX", "RY", "RZ", "CNOT", "CR", "CZ"],
      tagName1: ["H", "X", "Y", "Z", "RX", "RY", "RZ"],
      tagName2: ["CNOT", "CR", "CZ"],
      tableItem: ["Q0", "Q1", "Q2"],
      resData: [1, 3, 6],
      testData: {},
      new: [],
      arrayData: [],
      testArray: [
        ["df", "fdf", 43],
        [3, 5],
        ["sa", 23, 34],
        [33, 33],
      ],
      code: 'OPENQASM 2.0; \ninclude "qelib1.inc";\n',
      initCode: 'OPENQASM 2.0; \ninclude "qelib1.inc";',
      lineNumbers: true,
    };
  },
  computed: {
    strTableItem(a) {
      console.log(this.tableItem);
      return this.tableItem.join("|");
    },
    // isRactTag() {
    //   console.log(this.currentTag, this.initDropData);
    //   if (
    //     this.currentTag ==
    //     ("H" || "X" || "Y" || "Z" || "RX" || "RY" || "CONT" || "CR" || "CZ")
    //   )    return "ml-2 tag-item";
    //   return "tag-item mytag";

    //    this.currentTag ==("H" || "X" || "Y" || "Z" || "RX" || "RY" || "CONT" || "CR" || "CZ") ? "ml-2 tag-item":"tag-item mytag";
    // },
    //   tagClassName(e){
    //    console.log(e)

    // // tag-item mytag
    //       // this.tagClassName
    //   }
  },
  methods: {
    resetDragElement2(evt) {
      // const { target, clone, item, oldIndex } = evt;
      const { target, clone, item } = evt;
      // 考虑到在容器中，会存在部分元素可以拖拽，而非所有元素都可以拖拽，若使用 evt.oldIndex 就会导致存在 bug
      const oldIndex = Array.from(target.children).findIndex(
        (child) => child === clone
      );
      if (target.contains(clone)) {
        target.removeChild(clone);
        target.insertBefore(item, target.children[oldIndex]);
      }
    },
    resetDragElement(e, ele) {
      const { from, target, clone, item, oldIndex, to } = e;
      console.log(e, from, target, clone, item, oldIndex);
      let res = from.children[oldIndex];
      if (from.contains(clone)) {
        from.removeChild(clone);
        let item2 = ele.cloneNode("deep");
        console.log(res);
        from.insertBefore(item2, from.children[oldIndex]);
      }
    },
    // 电路点击后的事件
    circleClick(e) {
      console.log("click");
      this.showCircle = false;
      let currentCircle = e.target;
      let parent = e.currentTarget.parentElement;
      let circleDoms = parent.getElementsByClassName("circle");
      // let circleDoms = e;
      // console.log(circleDoms);
      let line = parent.getElementsByClassName("connect-line")[0];
      circleDoms = Array.from(circleDoms);
      // 去除其他圆圈
      circleDoms.forEach((item) => {
        // if (item != currentCircle) {
        // item.remove();
        item.style.display = "none";
        // }
      });
      // 更改对应圆圈的样式
      currentCircle.classList.add("active-circle");
      //  更改对应点击线路样式

      let lineTop = currentCircle.style.top;
      let lineHeight = Math.abs(parseInt(lineTop) - 10) + "px";
      if (parseInt(lineTop) < 0) {
        line.style.height = Math.abs(parseInt(lineTop)) + "px";
        line.style.top = parseInt(lineTop) + "px";
      } else {
        line.style.height = lineHeight;
      }

      line.style.display = "block";
    },
    deleteMenu() {
      console.log('a')
      
      this.showDeleteMenu = !this.showDeleteMenu;
    },
    test() {
      this.showAfterEditTag = !this.showAfterEditTag;
    },
    highlighter(code) {
      return highlight(code, languages.js); // languages.<insert language> to return html with markup
    },
    addTable() {
      if (this.tableItem.length < 5) {
        let name = "Q" + this.tableItem.length;
        this.tableItem.push(name);
        this.$nextTick(() => {
          this.initDrop();
        });
      } else {
        //  this.tableItem.pop();
      }
    },
    // 得到编辑器数据
    getEditData() {
      let dom = document.getElementsByClassName("mian-table")[0].children;
      dom = Array.from(dom);
      // 第一次循环
      dom.forEach((item, i) => {
        if (
          !item
            .getElementsByClassName("table-line")[0]
            .getElementsByClassName("tag-item")[0]
        )
          return;
        let num = item.getElementsByClassName("number")[0].innerText;
        let tagList = item
          .getElementsByClassName("table-line")[0]
          .getElementsByClassName("tag-item");
        tagList = Array.from(tagList);

        this.resData[i] = [];
        // 第二次循环
        tagList.forEach((item, j) => {
          if (item == null) return;
          let tag = item.innerText;
          console.log(tag, num);
          console.log(this.resData);

          this.resData[i][j] = tag + " " + num;
          // this.resData.push(tag + " " + num);
          // console.log(this.testData[j][i]);
        });
      });
      //   this.resData = this.resData[0].map((col, i) => this.resData.map(row => row[i]));

      var arr2 = [];
      for (var i = 0; i < this.resData[0].length + 2; i++) {
        arr2[i] = [];
      }
      for (var i = 0; i < this.resData.length; i++) {
        for (var j = 0; j < this.resData[i].length; j++) {
          arr2[j][i] = this.resData[i][j];
        }
      }
      this.resData = arr2;
      this.resData = this.resData.flat();
      // console.log(this.resData);
      //   this.resData = this.resData.flat();
      // this.transformArry(this.resData, this.testData);
    },
    // 初始化拖拽
    initDrop() {
      let from = document.getElementById("tag-container");
      let to = document.getElementsByClassName("mian-table-item");
      // .getElementsByClassName("table-line")
      to = Array.from(to);
      new Sortable(from, {
        group: {
          name: "shared",
          pull: "clone", // To clone: set pull to 'clone'
          put: false,
        },
        animation: 150,
        onRemove: (e) => {
          const { clone, from, to, oldIndex } = e;
          let title = e.to.getAttribute("data-drop");
          let attr = this.tagName[e.oldIndex];
          let index = e.newIndex;
          // console.log(e.item);
          let targetEle = from.children[oldIndex];
          console.log(targetEle);

          let fromItemChildren = e.item.children;
          fromItemChildren = Array.from(fromItemChildren);
          // console.log(fromItemChildren);
          this.resetDragElement(e, targetEle);

          // 对拖拽后的电路圆圈进行显示
          let circleItem = fromItemChildren.forEach((item) => {
            if (item.className == "circle") {
              item.style.display = "block";
            }
          });

          // 把数据数组放入对象中
          this.currentTag = title;
          if (!(this.testData[title] instanceof Array)) {
            this.testData[title] = [];
          }
          // this.testData[title].push(attr);
          // 数据拼接 H Q1
          // this.testData[title].splice(index, 0, attr + " " + title + "\n");
          let lowAttr = attr.toLowerCase();
          let lowTitle = title.toLowerCase();
          let title1 = lowTitle.split("")[0];
          let title2 = lowTitle.split("")[1];
          console.log(title1, title2);
          this.testData[title].splice(
            index,
            0,
            lowAttr + " " + title1 + "[" + title2 + "]" + ";" + "\n"
          );
          console.log(this.testData);
          // this.testData[title].push(attr + " " + title + "\n");
          // console.log(this.resData);
          // 对象数组转二维数组
          this.objToArr(this.testData);
          // console.log(this.resData);
          // 数组转置
          this.resData = this.transform(this.resData);
          // 数组扁平化
          this.resData = this.flatten(this.resData);
          // 过滤
          this.resData = this.resData.filter((item) => {
            return item != undefined;
          });
          this.code = this.initCode + "\n" + this.resData.join("");
          // console.log(item.getElementsByClassName("number")[0].innerText);
          // let res =
          //   e.to.innerText +
          //   " " +
          //   item.getElementsByClassName("number")[0].innerText;
          // this.resData.push(res);

          // this.resData.forEach((item, index) => {
          //   console.log(item);
          // });
          // 解决不能点击的问题
        },
        // onEnd:(evt) => {
        //   console.log(evt);
        // this.resetDragElement2(evt);
        // },
      });
      to.forEach((item) => {
        let item1 = item.getElementsByClassName("table-line")[0];
        new Sortable(item1, {
          group: {
            name: "shared",
            // pull: true,
          },
          animation: 150,
          // onChoose: () => {
          //   console.log("2onChoose");
          // },
          // onStart: () => {
          //   console.log("2onStart");
          // },
          // onEnd: () => {
          //   console.log("2End");
          // },
          // onClone: () => {
          //   console.log("2clone");
          // },
          // 行内位置更新
          onUpdate: (e) => {
            let title = e.to.getAttribute("data-drop");
            let tagName = e.clone.innerText;
            title = title.toLowerCase;
            tagName = tagName.toLowerCase;

            title = title.substr(0, 1) + "[" + title.substr(1) + "]";

            let newIndex = e.newIndex;
            let oldIndex = e.oldIndex;
            // console.log("2update", oldIndex, newIndex);
            this.currentTag = tagName;
            this.testData[title].splice(oldIndex, 1);
            this.testData[title].splice(
              newIndex,
              0,
              tagName + " "+ title +';'+ "\n"
            );

            // console.log(this.testData);
            this.objToArr(this.testData);
            this.resData = this.transform(this.resData);
            this.resData = this.flatten(this.resData);
            this.resData = this.resData.filter((item) => {
              return item != undefined;
            });
            this.code = this.initCode + "\n" + this.resData.join("");
          },
          // 行间位置移除
          onRemove: (e) => {
            let tagName = e.clone.innerText;
            let fromTitle = e.from.getAttribute("data-drop");
            let toTitle = e.to.getAttribute("data-drop");
            let newIndex = e.newIndex;
            let oldIndex = e.oldIndex;
            this.currentTag = tagName;

            toTitle = toTitle.toLowerCase();
            tagName = tagName.toLowerCase();
            toTitle = toTitle.substr(0, 1) + "[" + toTitle.substr(1) + "]";
            console.log(toTitle);
            // 只能行间拖动，不能往最上边列表的拖

            if (toTitle) {
              // console.log(this.testData);
              // 如果没有那一行,就创建那一行
              if (!this.testData[toTitle]) {
                this.testData[toTitle] = [];
              }
              this.testData[fromTitle].splice(oldIndex, 1);
              this.testData[toTitle].splice(
                newIndex,
                0,
                tagName + " "  + toTitle +';'+ "\n"
              );
              //拖回了最开始的列
            } else if (e.to.id == "tag-container") {
              this.testData[fromTitle].splice(oldIndex, 1);
            }
            // console.log("2remove",this.testData);
            this.objToArr(this.testData);
            // console.log(this.resData);
            this.resData = this.transform(this.resData);
            // console.log(this.resData);
            this.resData = this.flatten(this.resData);
            this.resData = this.resData.filter((item) => {
              return item != undefined;
            });
            this.code = this.initCode + "\n" + this.resData.join("");
          },
          // onMove: () => {
          //   console.log("2move");
          // },
        });
      });
    },
    objToArr(obj) {
      this.resData = [];
      for (let key in obj) {
        // console.log(key, obj[key]);
        this.resData.push(obj[key]);
      }
      // console.log(this.resData);
      // this.resData.flat();
      // console.log(this.resData);
    },
    flatten(arr) {
      while (arr.some((i) => Array.isArray(i))) {
        arr = [].concat(...arr);
      }
      return arr;
    },
    // 数组转置
    transform(arr) {
      // for (var i = 0; i < arr.length; i++) {
      //   for (var j = 0; j < i; j++) {
      //     const arr = mat[i][j];
      //     mat[i][j] = mat[j][i];
      //     mat[j][i] = tmp;
      //   }
      // }
      // var arr2 = [];
      // for (var i = 0; i < arr.length + 6; i++) {
      //   arr2[i] = [];
      // }
      // for (var i = 0; i < arr.length; i++) {
      //   for (var j = 0; j < arr.length; j++) {
      //     if (arr[i][j] != '') {
      //       arr2[j][i] = arr[i][j];
      //     }
      //   }
      // }
      let maxLength = this.getArrMaxLength(arr) + 1;
      let a = new Array(maxLength).fill("1");
      // console.log(a);
      // let arr2 = [2,34,5,7,8,934,,34,23].map(function (col, i) {
      let arr2 = a.map(function (col, i) {
        return arr.map(function (row) {
          return row[i];
        });
      });
      // console.log(arr2);
      // 过滤转置后的undefine项
      let arr3 = [];
      arr2.forEach((item, i) => {
        let res = item.filter(function (s) {
          return s !== undefined;
        });
        if (res) {
          arr3.push(res);
        }
      });
      // console.log(arr3);
      return arr3;
    },
    transformArry(array, n) {
      array.forEach((item, i) => {
        item.forEach((item2, j) => {
          n.push(array[j][i]);
        });
      });
      // console.log("resData", this.resData);
      // console.log("testData", this.testData);

      // this.testData.push(1);
      // this.testData.splice(this.array.length - 1, 1);
    },
    // 变化时语法检测
    editorChange(e) {
      let str = this.tableItem;
      str = str.join("|").toLowerCase();
      // console.log(str)

      var reg = new RegExp(
        "(h|x|y|z|rx|ry|rz|cnot|cr|cz)\\sq\\[(0|1|2|3|4|5)\\];",
        "g"
      );
      // let reg = /(H|X|Y|Z|RX|RY|RZ|CNOT|CR|CZ)\s(Q1|Q2|Q3)/g;
      // data.substring(37)

      let resArr = e.target._value.split("\n");
      resArr.splice(0, 2);
      if (resArr[resArr.length - 1] == "") {
        resArr.pop();
      }
      resArr.forEach((item) => {
        if (!reg.test(e.target._value)) {
          this.errmsg = true;
        } else {
          this.errmsg = false;
        }
      });
      // this.initDropData = resArr
      if (this.errmsg == true) return;
      console.log(resArr);
      this.initDropData = this.getFormatData(resArr);
      // console.log(this.initDropData);
      this.updateView(this.initDropData);
      // console.log(this.initDropData)
    },
    // 格式化数据,按Q1，Q2名字排列
    getFormatData(data) {
      let obj = {};
      data.forEach((item) => {
        let [value, name] = item.split(" ");
        let upValue = value.toUpperCase();
        let upName = name.toUpperCase();
        let [a, b, c, d] = upName.split("");
        upName = a + c;
        console.log(upValue, upName);
        if (!(obj[upName] instanceof Array)) {
          obj[upName] = [];
        }
        obj[upName].push(upValue);
      });
      // console.log(obj);
      return obj;
    },
    // 改变编辑器数据更新视图
    updateView(data) {
      // 先清除原有dom
      let dom = document.getElementsByClassName("mian-table")[0].children;
      dom = Array.from(dom);
      // 第一次循环
      dom.forEach((item, i) => {
        if (
          !item
            .getElementsByClassName("table-line")[0]
            .getElementsByClassName("tag-item")[0]
        )
          return;
        let name = item.getElementsByClassName("number")[0].innerText;
        let tableItem = item.getElementsByClassName("table-line")[0];
        let tagList = tableItem.getElementsByClassName("tag-item");
        tagList = Array.from(tagList);

        // 第二次循环
        // console.log(this.initDropData);
        tagList.forEach((item, j) => {
          item.remove();
        });
      });
      // 展示更改后数据生成的页面
      this.showAfterEditTag = false;
      setTimeout(() => {
        this.showAfterEditTag = true;
      });
      // 初始化拖拽
      this.initDrop();
    },
    getArrMaxLength(arr) {
      let max = 0;
      let temMax;
      arr.forEach((item) => {
        temMax = item.length;
        if (temMax > max) {
          max = temMax;
        }
        temMax = 0;
      });
      return max - 1;
    },
    // 移除拖拽表
    removeTable() {},
    editorKeyDown(e) {
      if (e.code == "Enter") {
        console.log("回车");
      }
      // console.log(e);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initDrop();
    });
    let res = http

    
  },
  watch: {
    resData: {
      handler(array, oldName) {},
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin-top: 20px;
  height: 40px;
}
.header .btn {
}

.main .main-top {
  padding: 20px 20px;
  height: 400px;
  .main-top-header-right {
    span {
      margin-left: 20px;
      margin-right: 10px;
    }
    .select {
      margin-left: 5px;
    }
    .btn {
      margin-left: 5px;
    }
  }
}

.main .main-top {
  .main-tag {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .tag-item {
    margin-right: 16px;
    width: 30px;
    background-color: #f2f7ff;
    color: #6b699d;
  }
}

// 侧边
.aside {
  margin-top: 20px;
  padding: 20px 10px;
  box-sizing: border-box;
  height: 82vh;
  overflow-y: scroll;
  .aside-header-left {
    .warning-icon {
      margin-left: 5px;
      position: relative;
      top: 3px;
    }
  }
  .aside-header-right {
    padding-left: 20px;
    position: relative;
    .errmsg-btn {
      margin-left: 10px;
    }
    .more-btn {
      position: absolute;
      right: 10px;
    }
  }
  .aside-list {
    margin-top: 10px;
    .list-item {
      span {
        font-size: 12px;
      }
      .el-divider {
        margin: 0;
        margin-top: 10px;
      }
    }
  }
}

.table-container .table-add {
  position: relative;
  .add-btn {
    position: absolute;
    top: 0;
    left: 5px;
    color: #409eff;
    font-size: 20px;
  }
  .sub-btn {
    position: absolute;
    top: -45px;
    left: 2px;
    visibility: hidden;
  }
}
// 拖拽到的表
.table-container .mian-table .mian-table-item {
  display: flex;
  position: relative;

  .number {
    color: #999999;
    font-size: "16px";
  }
  .tag-item {
    position: absolute;
    margin-left: 130px;
    z-index: 1;
  }
  .table-line {
    //  border: 1px solid red;
    height: 30px;
    width: 7800px !important;
    margin-bottom: 20px;
    white-space: nowrap; /* 内容超出不换行 */
    .tag-item:first-child {
      // margin-left: 20px;
    }
    .tag-item {
      margin-left: 25px;
      z-index: 100;
      position: relative;
      width: 30px;
    }
  }
  .line {
    width: 8000px;
    height: 1px;
    margin-left: 20px;
    background-color: #c4c4c4;
    position: absolute;
    top: 14px;
    left: 10px;
  }

  .number {
  }
}

.table-container .number {
  display: flex;
}

.table-container .table-add .number .number-item {
  // letter-spacing: 62px;
  margin-left: 62px;
  width: 21px !important;
  font-size: 14px;
  color: #999;
  // margin-top: -20px;
}
.table-container .number .number-item:first-child {
  margin-left: 57px;
}

/deep/.mytag {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-color: #f9f2fd !important;
  color: #ae92b2;
  border: 1px solid #cdc4cf;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  position: relative;
  .circle {
    background-color: #fff;
    display: none;
    position: absolute;
    top: 40px;
    left: 5px;
    width: 10px;
    height: 10px;
    border: 4px solid #e3a9d7;
    border-radius: 50%;
  }
  .active-circle {
    display: block !important;
    background-color: #e3a9d7;
  }
  .connect-line {
    position: absolute;
    left: 11px;
    top: 28px;
    width: 5px;
    // height: 100px;
    background-color: #e3a9d7;
  }
}

.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #f5f6f7;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
/deep/.prism-editor__textarea:focus {
  outline: none;
  border: none;
  // /deep/.token {
  //   color: red !important;
  // }
}
/deep/.prism-editor__editor {
  color: #000 !important;
  .token {
    color: #000 !important;
  }
}

.contextMenu-wrap {
  position: absolute;
  width: 180px;
  background-color: #fff;
  .menu-item {
    padding-left: 10px;
    box-sizing: border-box;
    height: 37px;
    line-height: 37px;
  }
}
</style>
