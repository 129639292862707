<template>
    <div class="login-wrapper">
      <el-form :model="form" :rules="rules" ref="info">
        <div class="top">欢迎使用量子计算云平台</div>
        <div class="pwds">修改密码</div>
      <el-form-item   prop="password">
               <el-input   placeholder="请输入新密码" v-model="form.password" show-password>
               <template slot="prepend"><li class="el-icon-lock"></li></template>
                </el-input>
      </el-form-item>
       <el-form-item   prop="NewPassword">
             <el-input  placeholder="请确认新密码" v-model="form.NewPassword" show-password>
             <template slot="prepend"><li class="el-icon-lock"></li></template>
            </el-input>
      </el-form-item> 
            <div style="width: 50vh">
              <el-button type="primary" style="width: 100%" class="geshi3" @click="onSave">
                修改密码
              </el-button>
              
            </div>
      </el-form>
    </div>
  </template>

<script>
import axios from 'axios'
import { passwordSave} from "@/utils/API/circuit.js";
import { ElMessage, ElMessageBox } from "element-plus";

export default{
    name:'Password',
    data(){
        return{  
            form:{
            password:'',
            confirmPassword:'',
            },  
            rules:{
                password:[
                    {required:true,message:'请输入新密码',trigger:'blur'},
                    {min:3,max:15,message:'长度不少于3位',trigger:'blur'}
                ],
                NewPassword:[
                    {required:true,message:'请确认新密码',trigger:'blur'},
                    {min:3,max:15,message:'长度不少于3位',trigger:'blur'}
                ]
            }
        }
    },
    mounted(){

    },
    methods:{
     async  onSave() {
      let password=this.form.password
      let NewPassword=this.form.NewPassword
      if(password!=NewPassword){
        ElMessage({
            showClose: true,
            message: "两次输入密码不一致",
            type: "error",
          });
          return;
      }
      let users_type = localStorage.getItem("type");
      let {data} = await passwordSave({
         users_type: users_type,
          password:this.form.password
         });
        if (data.code == 200) {
          ElMessageBox.alert("修改成功", "提示", {
            type: "success",
            callback: () => {
                localStorage.removeItem("token");
                //用户点击按钮后，跳转到主页
                this.$router.push({
                  name: "Login",
                })
              },
          });
        } 
    },

}

}

</script>
    
<style scoped>
/* 背景 */
.login-wrapper {
  padding: 50px 20px;
  position: relative;
  box-sizing: border-box;
  background-image: url("~@/assets/backgroundImage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top {
  text-align: center;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: bold;
}

.pwds{
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    /* color: rgba(64, 158, 255); */
}

.geshi3 {
  text-align: center;
  margin-top: 2%;
}

/* 
.el-menu--horizontal>.el-menu-item {
  border-bottom: none;
  text-decoration: none;
} */

/* 去掉el-tab-pane底部灰色线条  */
/deep/.el-tabs__nav-wrap::after {
  height: 0 !important;
}
</style>
