<template>
  <div class="body">
    <div class="result">我的实验结果</div>
    <el-card style="width: 95%; margin: 0 auto">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="tabClick" @tab-change="tabChange">
        <el-tab-pane label="已结束实验" name="1">
          <el-card>
            <!-- 搜索表单 -->
            <el-form label-width="110px" class="ele-form-search">
              <el-row :gutter="5">
                <div class="left">
                  <el-col>
                    <el-form-item prop="title" style="width: 350px" label="实验名称">
                      <el-input placeholder="请输入实验名称" clearable v-model="title" style="width: 80%"></el-input>
                    </el-form-item>
                  </el-col>
                </div>

                <el-col :lg="8" :md="10">
                  <el-form-item label="实验平台:" prop="platform" style="width: 350px">
                    <el-select placeholder="Select" @change="getSearchData" v-model="platform" style="width: 100%">
                      <el-option label="qiskit量子计算模拟器" value="qiskit" />
                      <el-option label="中性原子量子计算模拟器" value="zhongxing" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="12">
                  <div class="ele-form-actions">
                    <el-button @click="getSearchData"> 查询 </el-button>
                    <el-button type="primary" @click="title = ''">重置</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </el-card>

          <!-- 表格 -->
          <el-card shadow="never" style="margin-top: 10px">
            <el-table :data="dataList" :default-sort="{
              prop: 'createTime',
              prop: 'startTime',
              prop: 'endTime',
              order: 'descending',
            }" style="width: 100%">
              <el-table-column prop="id" label="序号" width="80" />
              <el-table-column prop="title" label="实验名称" />
              <el-table-column label="用户id" width="80">
                <template #default="{row}">
                  {{row.admin_user_id>0? row.student_id : row.user_id}}
                </template>
              </el-table-column>
              <el-table-column prop="content" label="实验内容" />
              <el-table-column prop="update_time" sortable label="创建时间"></el-table-column>
              <el-table-column label="操作">
                <template #default="{ row }">
                  <el-button type="primary" @click="ToCopy(row.content)">复制</el-button>
                  <el-button type="primary" @click="DownloadReport(row)" v-if="userType=='stunumber'">下载报告</el-button>
                  <el-button type="danger" @click="doDelete(row.id)">删除</el-button>
                </template>
              </el-table-column>
              
            </el-table>
            <div class="geshi">
              <div class="demo-pagination-block">
                <el-pagination v-model:currentPage="currentPage4" v-model:page-size="pageSize4"
                  :page-sizes="[10, 50, 100, 200]" :small="true" :disabled="disabled" :background="background"
                  layout="total, sizes, prev, pager, next, jumper" :total="count" @size-change="handleSizeChange"
                  @current-change="handleCurrentChange" />
              </div>
            </div>
          </el-card>
        </el-tab-pane>

        <el-tab-pane label="未结束实验" name="0">
          <el-card>
            <!-- 搜索表单 -->
            <el-form label-width="110px" class="ele-form-search">
              <el-row :gutter="5">
                <div class="left">
                  <el-col>
                    <el-form-item prop="title" style="width: 350px" label="实验名称">
                      <el-input placeholder="请输入实验名称" clearable v-model="title" style="width: 80%"></el-input>
                    </el-form-item>
                  </el-col>
                </div>

                <el-col :lg="8" :md="10">
                  <el-form-item label="实验平台:" prop="platform" style="width: 350px">
                    <el-select placeholder="Select" @change="getSearchData" v-model="platform" style="width: 100%">
                      <el-option label="qiskit量子计算模拟器" value="qiskit" />
                      <el-option label="中性原子量子计算模拟器" value="zhongxing" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="12">
                  <div class="ele-form-actions">
                    <el-button> 查询 </el-button>
                    <el-button type="primary" @click="title = ''">重置</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </el-card>

          <!-- 表格 -->
          <el-card shadow="never" style="margin-top: 10px">
            <el-table :data="dataList" :default-sort="{
              prop: 'createTime',
              order: 'descending',
            }" style="width: 100%">
              <el-table-column prop="id" label="序号" width="80" />
              <el-table-column prop="title" label="实验名称" />
              <el-table-column label="用户id" width="80">
                <template #default="{row}">
                  {{row.admin_user_id>0? row.student_id : row.user_id}}
                </template>
              </el-table-column>
              <el-table-column prop="content" label="实验内容" />
              <el-table-column prop="update_time" sortable label="创建时间" />
            </el-table>

            <div class="geshi">
              <div class="demo-pagination-block">
                <el-pagination v-model:currentPage="currentPage4" v-model:page-size="pageSize4"
                  :page-sizes="[100, 200, 300, 400]" :small="true" :disabled="disabled" :background="background"
                  layout="total, sizes, prev, pager, next, jumper" :total="count" @size-change="handleSizeChange"
                  @current-change="handleCurrentChange" />
              </div>
            </div>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import axios from "axios";
import VueAxios from "vue-axios";
import { Search } from "@element-plus/icons-vue";
import Clipboard from "clipboard";
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  name: "MyExperiment",
  components: {
    Search: Search,
  },
  data() {
    return {
      title: "", //实验标题
      state: 1, //实验状态，已完成和待完成
      platform: "zhongxing", //实验平台
      activeName: "1",
      dataList: [],
      count: 0,
      // 存入的复制的值
      textToCopy: " ",
      userType:'stunumber'
    };
  },
  created() {
    this.userType=localStorage.getItem('type');
    this.getAllData()
    console.log('123123');
    this.getSearchData()
    console.log('456456');

  },
  methods: {
    getAllData() {
       // 获取type
      let users_type=localStorage.getItem('type')
      axios({
        method: "POST",
        url: "http://qcloud.cascoldatom.com/api/project",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          contentType: "application/json",
        },
        data:{
          title: "",
          state: this.state,
          platform: this.platform,
          users_type:users_type
        },
        timeout: 1000,
      }).then((res) => {
        this.count = res.data.data.total;
        res.data.data.data.forEach((item) => {
          item.update_time = item.update_time.replace(/T/, " ");
        });
        this.dataList = res.data.data.data;
      });
    },
    // 复制
    ToCopy(doCopy) {
      var input = document.createElement("textarea"); // 创建多行输入框
      input.value = doCopy; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy",false); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功！');
    },
    // 下载报告
    DownloadReport(row_date){
    axios({
     method: 'post',
     url: 'http://qcloud.cascoldatom.com/api/downloadreport', 
     headers: {
     Authorization: "Bearer " + localStorage.getItem("token"),
     contentType: "application/json",
      },
     responseType: 'blob',
     data:{id:row_date.id},
     }).then(res=> {
     console.log(res);
      let blob = new Blob([res.data]); // 为blob设置文件类型
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      let time = parseInt(new Date().getTime() / 1000) + '';
      a.download = row_date.id+'_'+time+'.doc';
      a.style.display='none';
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
      window.URL.revokeObjectURL(url);
}) 

    },
    
    // 删除
    doDelete(projectid) {
      // 获取type
      let users_type=localStorage.getItem('type')
      ElMessageBox.confirm(
        '你确定要删除吗？',
        '提示框',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          axios({
            url: 'http://qcloud.cascoldatom.com/api/projectdelete',
            method: 'post',
            data: { projectid: projectid,users_type:users_type },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              contentType: "application/json",
            },
            timeout: 1000,
          }).then((res) => {
            console.log(res, '999');
            try {
              ElMessage({
                type: 'success',
                message: '删除成功',
              })
              this.getAllData()
            } catch (error) {
              console.log(error);
            }
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '取消删除',
          })
        })

    },
    getSearchData() {
       // 获取type
       let users_type=localStorage.getItem('type')
      axios({
        method: "POST",
        url: "http://qcloud.cascoldatom.com/api/project",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          contentType: "application/json",
        },
        data: {
          title: this.title,
          state: this.state,
          platform: this.platform,
          users_type:users_type
        },
        timeout: 1000,
      }).then((res) => {
        console.log(res.data);
        this.count = res.data.data.total;
        this.dataList = res.data.data.data;
      });
    },
    tabChange() {
      this.state = this.activeName;
      this.getAllData();
    },
    runProject() { },
  },
  mounted() {
    this.getAllData();
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100vh;
  background-color: #f0f5fb;
}

.result {
  line-height: 70px;
  width: 100%;
  height: 5%;
  margin-left: 3%;
  font-size: 25px;
  margin-bottom: 2%;
}

.geshi {
  position: relative;
  width: 100%;
  height: 20px;
}

.demo-pagination-block {
  position: absolute;
  left: 55%;
  top: 50%;
}

/* 去掉el-tab-pane底部灰色线条  */
/deep/.el-tabs__nav-wrap::after {
  height: 0 !important;
}
</style>
