<template>
  <div class="body">
    <div class="result">技术文档</div>
    <div class="bigbox">
      <el-card shadow="never" class="yangshi" style="width: 20%; margin-left: 2%">
        <el-menu v-for="article in articlelistPlus" :key="id" unique-opened class="left-menu" background-color="#ffffff"
          text-color="black" active-text-color="#eff7ff">
          <el-sub-menu index="0">
            <template #title>
              <el-icon>
                <Tools />
              </el-icon>
              <span>{{ article.title }}</span>
            </template>
            <el-menu-item class="ziti" v-for="(item, index) in Object.values(article.articlelist)"
              @click="getDetailArticle(Object.keys(article.articlelist), index)" :key="index">{{
                item
              }}</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-card>
  
        <el-card shadow="never" class="" style="width: 75%; margin-left: 1%">
          <div v-html="articlecontent.content"></div>
          <!-- 2023/3/30  -->
          <!-- <img :src="getImgUrl(articlecontent.thumb)" alt="" style="width: 60%;"> -->
        </el-card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueAxios from "vue-axios";
import { Tools, UserFilled } from "@element-plus/icons-vue";
export default {
  name: "document",
  // components的作用就是将组件注册成一个虚拟标签
  components: {
    Tools: Tools,
    UserFilled: UserFilled,
  },
  data() {
    return {
      articlelistPlus: [],
      articlecontent: {},
    };
  },
  created() {
  },
  mounted() {
    // 获取的侧边栏数据
    axios({
      url: 'http://qcloud.cascoldatom.com/api/cateList',
      method: 'get'
    }).then(({ data: res }) => {
      console.log(res, '456456');
      this.articlelistPlus = res.data
    })
  },
  methods: {
    // 获取的子级模块的内容
    async getDetailArticle(id, index) {
      console.log(id, '9999');
      let itemId = id[index]
      // console.log(articleNumber, '777');
      await axios({
        method: "post",
        url: `http://qcloud.cascoldatom.com/api/article/${itemId}`,
        headers: {
          contentType: "application/json",
        },
        // data: JSON.stringify(articleData),
        timeout: 1000,
      }).then(({ data: res }) => {
        console.log(res, '123123');
        // console.log(res.data.data.articlecontent);
        this.articlecontent = res.data
      });
    },
    // 将地址与后台获取的图片地址进行拼接
    getImgUrl(img) {
      console.log(img, '123124');
      return "http://qcloud.cascoldatom.com" + img
    }
  },
}


  // getDetailArticle(id) {
  //   console.log(parseInt(id));
  //   let article = {
  //     id: id
  //   }
  //   axios({
  //     url: `http://qcloud.cascoldatom.com/api/article/${id}`,
  //     method: 'post',
  //     headers: {
  //       contentType: "application/json",
  //     },
  //     // params: article

  //   }).then(({ data: res }) => {
  //     console.log(res, '123123');
  //   })
  // },
  // test() {
  //   axios({
  //     url: 'http://qcloud.cascoldatom.com/api/cateList',
  //     method: 'get'
  //   }).then(({ data: res }) => {
  //     console.log(res);
  //     res.data.forEach(item => {
  //       console.log(item, '666');
  //       // item.articlelist.forEach(info => {
  //       //   console.log(info, 'ooo')
  //       // })
  //       // for (let key in item.articlelist) {
  //       //   console.log(key, 'lll');
  //       // }
  //       console.log(Object.keys(item.articlelist));
  //     })
  //   })
  // }

  //   getDetailArticle(id) {
  //   console.log(id, '9999');
  //   let articleNumber = 0
  //   id.find(item => {
  //     console.log(item, '000');
  //     // articleNumber = item
  //     // console.log(articleNumber, '777');
  //     axios({
  //       method: "post",
  //       url: `http://qcloud.cascoldatom.com/api/article/${id}`,
  //       headers: {
  //         contentType: "application/json",
  //       },
  //       // data: JSON.stringify(articleData),
  //       timeout: 1000,
  //     }).then(({ data: res }) => {
  //       console.log(res, '123123');
  //       // console.log(res.data.data.articlecontent);
  //       // this.articlecontent = res.data.data.articlecontent.content;
  //     });
  //   })

  //   //   var articleData = {
  //   //     id: parseInt(articleNumber),
  //   //   };


  //   // var text = this.articlecontent;
  //   // var res = text.replace(
  //   //   /< img [^>]*src=['"]([^'"]+)[^>]*>/gi,
  //   //   function (match, cap) {
  //   //     return '< img src="http://www.baidu.com" />';
  //   //   }
  //   // );
  //   // this.articlecontent = res
  //   // },
  // },
  // }


//   mounted() {
//     axios({
//       method: "get",
//       url: "http://qcloud.cascoldatom.com/api/cateList",
//       timeout: 1000,
//     }).then((res) => {
//       console.log(res.data, '456456');
//       this.articlelist = res.data.data.articlelist;
//       console.log(this.articlelist);
//     });

//     // axios({
//     //   method: "POST",
//     //   url: "http://qc.cascoldatom.com/api/article",
//     //   timeout: 1000,
//     // }).then((res) => {
//     //   console.log(res.data);
//     // });
//   },
// };
</script>
<style scoped>
.body {
  width: 100%;
  background-color: #f0f5fb;
}

.bigbox {
  display: flex;
  width: 98%;
  justify-content: space-between;
}

.result {
  line-height: 70px;
  width: 100%;
  height: 5%;
  margin-left: 3%;
  font-size: 25px;
}

.yangshi {
  height: 700px;
}

.left-menu {
  width: 100%;
  border: none;
}

.ziti {
  font-size: 10px;
  color: black;
}
</style>
