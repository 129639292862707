<template>
  <div>
    <div>
      <draggable
        animation="300"
        :group="groupA"
        :list="list"
        class="list-group to"
        ghost-class="ghost"
      >
        <template v-for="(element, index2) in list" #item="{ element }">
          <div>
            <el-tag
              ref="tag"
              class="ml-2 tag-item"
              size="large"
              type="info"
              :key="index2"
              >{{element}}</el-tag
            >
            <!-- <div class="box">{{ element }}</div> -->
          </div>
        </template>
      </draggable>

      <div>----------</div>
      <draggable
        animation="300"
        :group="groupB"
        :list="list2"
        class="list-group to"
        ghost-class="ghost"
        style="display:flex;"
      >
        <template v-for="(element, index2) in list2" #item="{ element }">
          <div style="display:flex;">
            <div class="box" v-if="element == '1'">{{element}}</div>
            <el-tag
              ref="tag"
              class="ml-2 tag-item"
              size="large"
              type="info"
              :key="index2"
              v-else
              >{{element}}</el-tag
            >
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "home",
  components: {
    draggable,
  },
  props: {},
  data() {
    return {
      list: [1, 2, 3],
      list2: [2],
      groupA: {
        name: "site",
        pull: "clone",
        put: true,
      },
      groupB: {
        name: "site",
        pull: true,
        put: true,
      },
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeMount() {},
};
</script>

<style lang="less" scoped>
.box {
  width: 100px;
  height: 100px;
  background-color: red;
}
.to {
  width: 1000px;
  height: 400px;
  border: 1px solid red;
  flex-direction: column;
}
</style>
