<template>
  <div class="body" style="-webkit-user-select: text">
    <div class="login-wrapper">
      <div class="container">
        <div>推动量子技术</div>
        <div>革命的浪潮</div>
      </div>
      <div class="center">Advancing Quantun Technology Innovation</div>
      <el-button size="large" class="button1"><a style="color: #fff; text-decoration: none" href="https://www.cascoldatom.com/index.jsp">联系我们</a></el-button>
    </div>

    <div class="pagecenter">
      <div class="tupian1">
        <el-image :src="require('@/assets/Sphere.png')" style="width: 150px; height: 150px"></el-image>
      </div>
      <div class="pagecenter_top">
        <el-image :src="require('@/assets/1.jpg')" style="width: 370px; height: 250px"></el-image>
        <router-link style="color: #000; text-decoration: none" to="Circuit">图形化地构造电路</router-link>
      </div>
      <div class="tupian2">
        <el-image :src="require('@/assets/Sphere.png')"></el-image>
      </div>
      <div class="tupian3">
        <el-image :src="require('@/assets/Sphere.png')"></el-image>
      </div>
      <div class="pagecenter_footer">
        <a style="color: #000; text-decoration: none" href="http://lab.qc.cascoldatom.com">开发量子实验</a>
        <el-image :src="require('@/assets/2.jpg')" style="width: 370px; height: 250px"></el-image>
      </div>
    </div>

    <div class="footer">
      <ul style="width: 30%">
        <li class="ziti" style="margin-top: 40px">公司简介</li>
        <li>
          <p style="line-height: 30px; marign-left: -20px; font-size: 14px">
            中科酷原科技（武汉）有限公司是业内领先的量子技术创业企业，公司核心团队源自中国科学院精密测量科学与技术创新研究院，是国内最早开始中性原子量子技术研究的团队之一，先后承担了国家重大研究计划973项目、国家重点研发计划、中科院先导专项等多项量子精密测量和量子计算相关的国家级课题。
          </p>
        </li>
      </ul>

      <ul style="width: 22%; text-align: left; align-items: start; line-height: 30px; font-size: 14px">
        <li class="ziti" style="align-self: center">联系我们</li>
        <li>公司名称：中科酷原科技（武汉）有限公司</li>
        <li>邮箱：weixia@cascoldatom.com</li>
        <li>地址：武汉市洪山区邮科院烽火创新谷2号楼4楼东</li>
      </ul>

      <ul style="width: 20%; align-items: start; font-size: 14px; line-height: 30px; marign-top: -10px">
        <li class="ziti" style="align-self: center">公司文化</li>
        <li>使命：推动量子技术革命的浪潮</li>
        <li>愿景：成为国际领先的量子技术提供商</li>
        <li>价值观：诚信、利他、积极、正直</li>
      </ul>
    </div>
   
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.body {
  widows: 100%;
  height: 100%;
}
/* 背景 */
.login-wrapper {
  position: relative;
  padding: 50px 20px;
  position: relative;
  box-sizing: border-box;
  background-image: url("~@/assets/container.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
/* 字体 */
.container {
  position: absolute;
  font-size: 40px;
  font-weight: bold;
  top: 25%;
  left: 9%;
}
.center {
  position: absolute;
  left: 9%;
  top: 47%;
}
.button1 {
  position: absolute;
  left: 9%;
  top: 60%;
  background-color: #90add5;
  color: white;
  width: 165px;
  height: 50px;
}
.footer {
  background-color: #f6f6f6;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
}
.ziti {
  font-size: 20px;
  font-weight: bold;
}
.footer ul {
  width: 150px;
  height: 200px;
  display: flex;
  background-color: #f6f6f6;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  list-style: none;
}
.pagecenter {
  position: relative;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  height: 800px;
}
.pagecenter_top {
  margin: 0 auto;
  width: 70%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagecenter_footer {
  margin: 0 auto;
  width: 70%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tupian1 {
  z-index: -1;
  position: absolute;
  left: 70%;
  top: 10%;
}
.tupian2 {
  z-index: -1;
  position: absolute;
  left: 9%;
}
.tupian3 {
  z-index: -1;
  position: absolute;
  left: 70%;
  top: 80%;
}
</style>