// vue3中创建store实例对象的方法createStore()按需引入
import { createStore } from 'vuex'
export default createStore({
    actions: {
    },

    mutations: {
        token(state, value) {
            state.token = value;
        },
        changeLogin(state,value){
            state.isLogin = value
        }
        
    },
    state: {
        token: "",
        isLogin:true
    },

})