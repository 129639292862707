import axios from "axios";
// import router from '../router'
const service = axios.create({
  // 设置超时时间
  timeout: 5000,  
  baseURL: "http://qcloud.cascoldatom.com",
});

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const responseCode = response.status;
    if (responseCode === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    // 服务器返回不是 2 开头的情况，会进入这个回调
    // 可以根据后端返回的状态码进行不同的操作
    // const responseCode = error.response.status
    // switch (responseCode) {
    // 401：未登录
    // case 401:
    //     // 跳转登录页
    //     Message({
    //         type: 'error',
    //         message: '未登录'
    //     })
    //     break
    // // 403: token过期
    // case 403:
    //     // 弹出错误信息
    //     Message({
    //         type: 'error',
    //         message: '登录信息过期，请重新登录'
    //     })
    //     // 清除token
    //     localStorage.removeItem('token')
    //     // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
    //     setTimeout(() => {
    //         router.replace({
    //             path: '/login',
    //             query: {
    //                 redirect: router.currentRoute.fullPath
    //             }
    //         })
    //     }, 1000)
    //     break
    // // 404请求不存在
    // case 404:
    //     Message({
    //         message: '网络请求不存在',
    //         type: 'error'
    //     })
    //     break
    // // 其他错误，直接抛出错误提示
    // default:
    //     Message({
    //         message: error.response.data.message,
    //         type: 'error'
    //     })
    // }
    return Promise.reject(error);
  }
);

// export function get(url, data = {}) {
//     return new Promise((resolve, reject) => {
//         service.get(url, {
//             params: data
//         })
//             .then(res => {
//                 resolve(res)
//             })
//             .catch(err => {
//                 reject(err)
//             })
//     })
// }

// export function post(url, data) {
//     return new Promise((resolve, reject) => {
//         service.post(url, data)
//             .then(res => {
//                 resolve(res)
//             })
//             .catch(err => {
//                 reject(err, '请求错误')
//             })
//     })
// }




export default service;
