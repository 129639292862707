import { createApp } from 'vue';
import App from './App.vue';
import '@/assets/css/reset.css'
import '@/assets/common.js'
// 导入element插件
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import store from '@/store/index.js' // 引入
import { installCardDragger } from 'carddragger'
// import EleAdmin from 'ele-admin';

// 导入所有的el-icon图标
import * as ElIconModules from '@element-plus/icons-vue'
import ElementPlus from "element-plus";
import router from "./router/index.js";





// 创建vue的app程序
const app = createApp(App);

//  统一注册el-icon图标
for (let iconName in ElIconModules) {
    app.component(iconName, ElIconModules[iconName])
}


app.use(installCardDragger)



app.use(store);
app.use(router);
app.use(ElementPlus, {
    locale: zhCn
});
// 加载路由模块
app.mount("#app");
