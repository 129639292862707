// 路由配置文件 vue-router
import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
import Circuit from "../views/Circuit.vue";
import Document from "../views/Document.vue";
import HomePage from "../views/HomePage.vue";
import MyExperiment from "../views/MyExperiment.vue";
import password from '../views/password.vue'

import { ElMessageBox, formContextKey } from "element-plus";

import Test from "../views/myCircuit.vue";
import Test2 from "../views/Circuit2.vue";
const test3 = () => import('../views/test3.vue')
const test4 = () => import('../views/test4.vue')
const test5 = () => import('../views/test5.vue')
const test6 = () => import('../views/test6.vue')
const test7 = () => import('../views/test7.vue')


// 去创建一个路由管理对象
const router = createRouter({
  // 路由的模式
  history: createWebHashHistory(),
  // 配置路由规则
  routes: [
    {
      path: "/",
      redirect: {
        name: "HomePage"
      },
    },

    {
      path: "/HomePage",
      component: HomePage,
      name: "HomePage",
      meta: { title: '首页' },
    },
   
    {
      path: "/Login",
      component: Login,
      name: "Login",
      meta: { title: '登录' }
    },
    {
      path: "/Circuit",
      component: test3,
      name: "Circuit",
      meta: { title: '量子线路' }
    },
     {
      path: "/Updatepwd",
      component: password,
      name: "Updatepwd",
      meta: { title: '修改密码' }
    },
 
    {
      path: "/Document",
      component: Document,
      name: "Document",
      meta: { title: '技术文档' }
    },
    {
      path: "/MyExperiment",
      component: MyExperiment,
      name: "MyExperiment",
      meta: { title: '我的实验', isAuth: true }
    },
    {
      path: "/test",
      component: Test,
      name: "test",
      meta: { title: 'test' }
    },
    {
      path: "/test3",
      component: test3
    },
    {
      path: "/test4",
      component: test4
    },
     {
      path: "/test5",
      component: test5
    },
    {
      path: "/test6",
      component: test6
    },
    {
      path: "/test7",
      component: test7
    }
  ]
});


// 技术文档
router.beforeEach((to, from, next) => {
  if (to.meta.isAuth) { //判断是否需要鉴权
    let Token = localStorage.getItem('token')
    if (Token != undefined) {
      next()
    } else {
      ElMessageBox.alert("您还没有登录，请先登录", "提示", {
        type: "error",
      });
    }
  } else {
    next()
  }
})



//全局后置守卫：初始化时执行、每次路由切换后执行
router.afterEach((to, from) => {
  let Oldtoken = localStorage.getItem('token')


  if (to.path == "/HomePage" && from.path == "/Login" && Oldtoken != undefined) {
    window.location.reload();
  }

  if (to.path == "/Login" && from.path == "/Updatepwd" && Oldtoken == undefined) {
    window.location.reload();
  }
  
})


router.beforeEach((to, from, next) => {
  to.meta.title && (document.title = to.meta.title);
  
  next()
});


export default router;
