<template>
  <div class="login-wrapper">
    <el-form :model="formData" :rules="formDataRules" ref="info">
      <div class="top">欢迎使用量子计算云平台</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">

        <el-tab-pane label="手机号登录" name="first" class="table">
          <div class="first">
            <el-form-item prop="admin_tel">
              <el-input prefix-icon="Avatar" size="large" style="width: 50vh" v-model="formData.admin_tel"
                placeholder="请输入手机号"></el-input>
            </el-form-item>

            <div style="width: 75%; height: 4px"></div>
            <div class="geshi1" style="width: 50vh">
              <el-input placeholder="请输入验证码" clearable size="large" style="width: 55%" prefix-icon="Lock"
                v-model="formData.code1"></el-input>
              <el-button type="primary" style="width: 40%; margin-left: 5%" size="large" @click="toSendPhoneCode"
                :disabled="codeDisabled">
                {{ codeMsg }}
              </el-button>
            </div>
          </div>
          <el-checkbox label="记住手机号" class="geshi2" v-model="checked1" />
          <div style="width: 50vh">
            <el-button type="primary" style="width: 100%" class="geshi3" @click="submit1">登录</el-button>
          </div>
        </el-tab-pane>


      
    <el-tab-pane label="邮箱登录" name="second" class="table">
          <div class="first">
            <el-form-item prop="admin_email">
              <el-input prefix-icon="Avatar" size="large" style="width: 50vh" v-model="formData.admin_email"
                placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <div style="width: 75%; height: 4px"></div>
            <div class="geshi1" style="width: 50vh">
              <el-input placeholder="请输入验证码" clearable size="large" style="width: 55%" prefix-icon="Lock"
                v-model="formData.code2"></el-input>
              <el-button type="primary" style="width: 40%; margin-left: 5%" size="large" @click="toSendEmailCode"
                :disabled="codeDisabled">
                {{ codeMsg }}
              </el-button>
            </div>
          </div>
          <el-checkbox label="记住邮箱号" class="geshi2" v-model="checked2" />
          <div style="width: 50vh">
            <el-button type="primary" style="width: 100%" class="geshi3" @click="submit2">登录</el-button>
          </div>
        </el-tab-pane>
        
       
      <el-tab-pane label="密码登录"  name="stunumber" class="table" >
          <div class="first">
            <el-form-item prop="admin_stunumber">
              <el-input prefix-icon="Avatar" size="large" style="width: 50vh" v-model="formData.admin_stunumber"
                placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item prop="admin_password">
              <el-input prefix-icon="Avatar" size="large" style="width: 50vh" v-model="formData.admin_password"
                placeholder="请输入密码"></el-input>
            </el-form-item>
          </div>
          <el-checkbox label="记住账号" class="geshi2" v-model="checked3" />
          <div style="width: 50vh">
            <el-button type="primary" style="width: 100%" class="geshi3" @click="submit3">登录</el-button>
          </div>
        </el-tab-pane>
        

      </el-tabs>
    </el-form>
  </div>
</template>

<script>
// import API from "../utils/API/index.js"
import md5 from "js-md5";
import axios from "axios";
import VueAxios from "vue-axios";
import { Tools, UserFilled, Avatar } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "Login",
  components: {
    Tools: Tools,
    UserFilled: UserFilled,
    Avatar: Avatar,
  },
  data() {
    return {
      activeName: "first",
      codeDisabled: false,
      countdown: 60,
      codeMsg: "获取验证码",
      checked1: true,
      checked2: true,
      checked3: true,
      formData: {
        admin_tel: "",
        admin_email: "",
        admin_stunumber:"",
        admin_password:"",
        code1: "",
        code2: "",
        code3:''
      },
      //配置验证规则
      formDataRules: {
        admin_tel: [
          { required: true, message: "管理员手机号不能为空", trigger: "blur" },
          {
            validator(rule, value, callBack) {
              //要通过正则表达式来验证手机号
              let reg = /^1[356789]\d{9}$/;
              if (reg.test(value)) {
                //验证通过
                callBack();
              } else {
                //验证不能通过
                callBack(new Error("手机号格式不正确"));
              }
            },
            trigger: "blur",
          },
        ],
        admin_email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" },
          {
            pattern: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
        admin_stunumber: [
          { required: true, message: "账号不能为空", trigger: "blur" },
          {
            pattern: /^\S{1,40}$/,
            message: "账号格式不正确",
            trigger: "blur",
          },
        ],
        admin_password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            pattern: /^\S{1,40}$/,
            message: "密码格式不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 手机号验证码
    toSendPhoneCode() {
      // var userdata = {
      //   telephone: this.formData.admin_tel,
      // };
      axios({
        method: "POST",
        url: "http://qcloud.cascoldatom.com/api/sms_code",
        headers: {
          contentType: "application/json",
        },
        data: { 'telephone': this.formData.admin_tel },
        timeout: 3000,
      }).then((res) => {
        console.log(res.data);
        // let newcode1 = md5(res.data.data.smscode); //加密
        // localStorage.setItem("smsCode", newcode1);
        localStorage.setItem("smsCode", res.data.data.smscode)
        // localStorage.setItem("admin_tel", this.formData.admin_tel);
      });
      // 获取验证码倒计时
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.countdown > 0 && this.countdown <= 60) {
            this.countdown -= 1;
            this.codeDisabled = true;
            if (this.countdown !== 0) {
              const codeMsgs = "重新获取";
              this.codeMsg = codeMsgs.concat(this.countdown, "s");
            } else {
              clearInterval(this.timer);
              this.codeMsg = "获取验证码";
              this.countdown = 60;
              this.timer = null;
              this.codeDisabled = false;
            }
          }
        }, 1000);
      }
    },
    // 邮箱验证码
    toSendEmailCode() {
      // var emaildata = {
      //   email: this.formData.admin_email,
      // };
      // 获取验证码倒计时
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.countdown > 0 && this.countdown <= 60) {
            this.countdown -= 1;
            this.codeDisabled = true;
            if (this.countdown !== 0) {
              const codeMsgs = "重新获取";
              this.codeMsg = codeMsgs.concat(this.countdown, "s");
            } else {
              clearInterval(this.timer);
              this.codeMsg = "获取验证码";
              this.countdown = 60;
              this.timer = null;
              this.codeDisabled = false;
            }
          }
        }, 1000);
      }

      axios({
        method: "POST",
        url: "http://qcloud.cascoldatom.com/api/email_code",
        headers: {
          contentType: "application/json",
        },
        data: { email: this.formData.admin_email },
        timeout: 3000,
      }).then(function (res) {
        console.log(res.data);
        // let newcode2 = md5(res.data.data.emailcode); //加密
        localStorage.setItem("emailCode", res.data.data.emailcode);
      });
    },
    // 手机号登录
    submit1() {
      const sms_code = localStorage.getItem("smsCode");
      localStorage.setItem("type", "telephone");
      // let phonecode = md5(this.formData.code1);
      if (sms_code == this.formData.code1) {
        // var userdata = {
        //   type: "telephone",
        //   telephone: this.formData.admin_tel,
        // };
        axios({
          method: "POST",
          url: "http://qcloud.cascoldatom.com/api/login",
          headers: {
            Authorization: "",
            contentType: "application/json",
          },
          data: {
            type: "telephone",
            telephone: this.formData.admin_tel,
          },
          timeout: 3000,
        }).then((res) => {
          console.log(res.data);
          if (res.data.code === 200) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("admin_tel", this.formData.admin_tel);
            // if (this.checked1 == true) {
            // }
            // if (this.checked1 == false) {
            //   localStorage.removeItem("admin_tel");
            // }
            setTimeout(() => {
              console.log(localStorage.getItem("admin_tel"), this.formData.admin_tel);
            }, 3000);
            //登录成功
            ElMessageBox.alert("登录成功", "提示", {
              type: "success",
              callback: () => {
                //用户点击按钮后，跳转到主页
                this.$router.push({
                  name: "HomePage",
                });
              },
            });
          }
        });
      } else {
        //账号密码错误
        ElMessageBox.alert("验证码错误", "提示", {
          type: "error",
        });
      }
    },
    submit2() {
      const email_code = localStorage.getItem("emailCode");
      localStorage.setItem("type", "email");
      // let stu_code = md5(this.formData.code2);
      if (email_code === this.formData.code2) {
        // var userdata = {
        //   type: "email",
        //   email: this.formData.admin_email,
        // };
        let res = axios({
          method: "POST",
          url: "http://qcloud.cascoldatom.com/api/login",
          headers: {
            Authorization: "",
            contentType: "application/json",
          },
          data: {
            type: "email",
            email: this.formData.admin_email,
          },
          timeout: 3000,
        }).then((res) => {
          console.log(res.data);
          if (res.data.code === 200) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("admin_email", this.formData.admin_email);
            //登录成功
            ElMessageBox.alert("登录成功", "提示", {
              type: "success",
              callback: () => {
                //用户点击按钮后，跳转到主页
                this.$router.push({
                  name: "HomePage",
                });
              },
            });
          }

        });
      } else {
        //账号密码错误
        ElMessageBox.alert("验证码错误", "提示", {
          type: "error",
        });
      }
    },
// 账号密码登录 
    submit3() {
      localStorage.setItem("type", "stunumber");
         axios({
          method: "POST",
          url: "http://qcloud.cascoldatom.com/api/login",
          headers: {
            Authorization: "",
            contentType: "application/json",
          },
          data: {
            type: "stunumber",
            stunumber: this.formData.admin_stunumber,
            password: this.formData.admin_password,
          },
          timeout: 3000,
        }).then(res=>{
          if(res.data.code===200){
            localStorage.setItem("token",res.data.data.token);
            localStorage.setItem("admin_stunumber", this.formData.admin_stunumber);

            //成功登录
            ElMessageBox.alert("登录成功", "提示", {
              type: "success",
               callback: () => {
                //用户点击按钮后，跳转到主页
                this.$router.push({
                  name: "HomePage",
                })
              },
            })
          }
          else {
            //账号密码错误
            ElMessageBox.alert("账号密码错误", "提示", {
              type: "error",
            })
         }
      })
    },
  },
  mounted() {
    const Oldtelephone = localStorage.getItem("admin_tel");
    const Oldemail = localStorage.getItem("admin_email");
    const Oldstunumberl = localStorage.getItem("admin_stunumber");
    if (Oldtelephone != "") {
      this.formData.admin_tel = Oldtelephone;
      this.checked1 = true;
    }
    if (Oldtelephone == undefined) {
      this.checked1 = false;
    }

    if (Oldemail != " ") {
      this.formData.admin_email = Oldemail;
      this.checked2 = true;
    }
    if (Oldemail == undefined) {
      this.checked2 = false;
    }

    if (Oldstunumberl != " ") {
      this.formData.admin_stunumber = Oldstunumberl;
      this.checked3 = true;
    }
    if (Oldstunumberl == undefined) {
      this.checked3 = false;
    }
  


  },
};
</script>

<style scoped>
/* 背景 */
.login-wrapper {
  padding: 50px 20px;
  position: relative;
  box-sizing: border-box;
  background-image: url("~@/assets/backgroundImage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top {
  text-align: center;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: bold;
}

/* .container {
  width: 35%;
  height: 60%;
} */
.geshi {
  display: flex;
}

.geshi2 {
  margin-top: 1%;
}

.geshi3 {
  text-align: center;
  margin-top: 2%;
}

.first {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-buttom: none;
}

.el-menu--horizontal>.el-menu-item {
  border-bottom: none;
  text-decoration: none;
}

/* 去掉el-tab-pane底部灰色线条  */
/deep/.el-tabs__nav-wrap::after {
  height: 0 !important;
}
</style>
