<template>
  <el-menu :default-active="activeIndex" class="el-menu-demo" @select="handleSelect" 
  id="top" mode="horizontal" router="true" >
    <el-menu-item style="border: none" id="logo">
      <el-image :src="require('@/assets/logo.png')" style="height: 50px; width: 120px"></el-image>
    </el-menu-item>
    <el-menu-item index="/HomePage">首页</el-menu-item>
    <el-menu-item index="/Circuit">量子线路</el-menu-item>
    <el-menu-item index="/MyExperiment">我的实验</el-menu-item>
    <el-menu-item index="/Document">技术文档</el-menu-item>

    <!-- <div class="flex-grow" /> -->
    <el-menu-item index="/Login" style="margin-left: 45%" v-if="!isLogin">
      <el-icon>
        <UserFilled />
      </el-icon>
      <span>登录</span>
    </el-menu-item>
    <el-sub-menu style="margin-left: 42%" v-if="type == 'telephone' && isLogin">
      <template #title>{{ telephone }}</template>
      <div @click="logOut">
        <el-menu-item index="/Login">退出登录</el-menu-item>
      </div>
    </el-sub-menu>
    <el-sub-menu style="margin-left: 38%" v-if="type == 'email' && isLogin">
      <template #title>{{ email }}</template>
      <div @click="logOut">
        <el-menu-item index="/Login">退出登录</el-menu-item>
      </div>
    </el-sub-menu>

    <el-sub-menu style="margin-left: 38%" v-if="type == 'stunumber' && isLogin">
      <template #title>{{ stunumber }}</template>
      <div @click="logOut">
        <el-menu-item index="/Login">退出登录</el-menu-item>
      </div>
      <div>
        <el-menu-item index="/Updatepwd">修改密码</el-menu-item>
      </div>
    </el-sub-menu>

  </el-menu>
  <router-view ref="Childmain"></router-view>
</template>
      
<script>
import { UserFilled } from "@element-plus/icons-vue";
import { useStore } from "vuex";
//创建store变量
const store = useStore();
export default {
  name: "document",
  // components的作用就是将组件注册成一个虚拟标签
  components: {
    UserFilled: UserFilled,
  },
  data() {
    return {
      type: "", //telephone为手机登录，email为邮箱登录  ，stunumber 学生登陆
      isLogin: false,
      token: "",
      telephone: "",
      email: "",
      stunumber:"",
      activeIndex: 0,
    };
  },
  methods: {
    logOut() {
      this.isLogin = false;
      localStorage.removeItem("token");
    },

},
  mounted() {
    const Oldtelephone = localStorage.getItem("admin_tel");
    const Oldemail = localStorage.getItem("admin_email");
    const Oldestunumber = localStorage.getItem("admin_stunumber");
    const Oldtoken = localStorage.getItem("token");
    const Oldtype = localStorage.getItem("type");
    // console.log(Oldtype, Oldtoken, Oldtelephone);
    // 获取手机号
    if (Oldtelephone != undefined && Oldtoken != undefined) {
      this.telephone = Oldtelephone;
      this.isLogin = true;
    }
    // 获取邮箱
    if (Oldemail != undefined && Oldtoken != undefined) {
      this.email = Oldemail;
      this.isLogin = true;
    }

    // 获取学号
    if (Oldestunumber != undefined && Oldtoken != undefined) {
      this.stunumber = Oldestunumber;
      this.isLogin = true;
    }
    // 获取登录方式
    if (Oldtype != undefined && Oldtoken != undefined) {
      this.type = Oldtype;
    }

    },
    
   
};
</script>

<style scoped>
body {
  zoom: 100%;
}
* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  /* -webkit-user-select: none; */
  -webkit-touch-callout: none;
}
html {
}
html,
body,
.flex-grow {
  flex-grow: 1;
}
#logo {
  margin-right: 65px;
  /* margin-bottom: 20px; */
  line-height: 50px;
}
#app {
  width: 100%;
  height: 100vh;
}
.el-menu-demo {
  height: 40px;
}
.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
  text-decoration: none;
  padding: 0 20px;
}

.el-menu-item.is-active {
  border-bottom: 2px solid #409eff !important;
}

.el-menu-item:hover {
  /* background-color: #d9ecff !important; */
  /* border-bottom: 2px solid #409eff !important; */
  /* color: #409eff !important;
  box-sizing: border-box; */
}

.stylepwd{
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
}
.stylepwd:hover{
  /* width: 100%; */
 color: red;
 /* background-color: #ecf5ff; */
}

</style>