import http from './request'

//得到图表数据
export const getEchartData = (params) => {
    return http({
        method: 'POST',
        url: '/api/compute',
        data:params
    })
}

// 保存实验数据
export const projectSave = (params) => {
    return http({
        method: 'POST',
        url: '/api/projectsave',
        data: params
    })
}


export const passwordSave = (params) => {
    return http({
        method: 'POST',
        url: '/api/updatepwd',
        data: params,
        
    })
}





